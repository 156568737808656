import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {Camera} from '@ionic-native/camera/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { File } from '@ionic-native/file/ngx';
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
import { HttpClientModule } from '@angular/common/http';
import { Health } from '@ionic-native/health/ngx';
import { ScreenOrientation} from "@ionic-native/screen-orientation/ngx";
import { Calendar } from '@ionic-native/calendar/ngx';
import { BluetoothLE } from '@ionic-native/bluetooth-le/ngx';
import { HealthKit } from '@ionic-native/health-kit/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { Media } from '@ionic-native/media/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,HttpClientModule, IonicModule.forRoot({animated: false}), AppRoutingModule],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    Camera,
    HTTP,
    File,
    StreamingMedia,
    YoutubeVideoPlayer,
    Health,
    ScreenOrientation,
    Calendar,
    BluetoothLE,
    HealthKit,
    InAppBrowser,
    Insomnia,
    Media,
    NativeAudio,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
